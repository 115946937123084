<template>
  <div class="grid grid-cols-12 gap-6 mt-5">
    <Address />
    <SendAssets />
    <TransactionsHistory />
  </div>
</template>


<script>
import { defineComponent } from "vue";
import Address from "@/components/wallet/Address.vue";
import SendAssets from "@/components/wallet/SendAssets.vue";
import TransactionsHistory from "@/components/wallet/TransactionsHistory.vue";

export default defineComponent({
  name: "Wallet",
  components: {
    Address,
    SendAssets,
    TransactionsHistory,
  },
  mounted: function () {
    this.$store.commit("updateTopBarTitle", this.$i18n.t("menu.wallet"));
  },
});
</script>
