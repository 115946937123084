<script>
import QRCode from 'qrcode';
import { defineComponent, h, ref, watch } from 'vue';

export default defineComponent({
    props: {
        value: {
            type: [String, Array],
            required: true,
            validator(value) {
                if (typeof value === 'string') {
                    return true;
                }
                return value.every(({ data, mode }) => typeof data === 'string' && MODES.includes(mode));
            },
        },
    },
    setup(props, { attrs, emit }) {
        const dataUrlRef = ref();
        const toDataURL = () => {
            QRCode.toDataURL(props.value)
                .then(dataUrl => {
                dataUrlRef.value = dataUrl;
            })
                .catch((err) => emit('error', err));
        };
        watch(props, toDataURL, { immediate: true });
        return () => h('img', Object.assign(Object.assign({}, attrs), { src: dataUrlRef.value }));
    },
});
</script>