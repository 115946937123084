<template>
  <div class="col-span-12 xl:col-span-6">
    <div class="intro-y box">
      <div
        class="
          flex flex-col
          sm:flex-row
          items-center
          p-5
          border-b border-gray-200
          dark:border-dark-5
        "
      >
        <h2 class="font-medium text-base mr-auto">
          {{ $t("views.wallet.walletAddress") }}
        </h2>
      </div>

      <div class="p-5">
        <div class="flex items-center">
          <div class="mr-3">
            <CreditCardIcon />
          </div>
          <div class="">
            <a
              :href="blockExplorer"
              target="_blank"
              class="text-lg hover:underline break-all"
              >{{ address }}</a
            >
          </div>
          <div class="ml-5">
            <button type="button" @click="copyToClipboard">
              <CopyIcon />
            </button>
          </div>
          <div class="ml-2">
            <a
              href="javascript:;"
              data-toggle="modal"
              data-target="#qr-code-modal"
            >
              <svg
                height="25"
                viewBox="0 0 512 512"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path d="m40 40h80v-40h-120v120h40z" />
                  <path d="m392 0v40h80v80h40v-120z" />
                  <path d="m40 392h-40v120h120v-40h-80z" />
                  <path d="m472 472h-80v40h120v-120h-40z" />
                  <path d="m76 236h160v-160h-160zm40-120h80v80h-80z" />
                  <path d="m436 76h-160v160h160zm-40 120h-80v-80h80z" />
                  <path d="m76 436h160v-160h-160zm40-120h80v80h-80z" />
                  <path d="m316 316v-40h-40v80h40v40h40v-80z" />
                  <path d="m356 396h80v40h-80z" />
                  <path d="m396 356h40v-80h-80v40h40z" />
                  <path d="m276 396h40v40h-40z" />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <QrCode :address="address" />
    </div>
  </div>
  <div class="col-span-12 xl:col-span-6">
    <div class="box h-full intro-x">
      <div
        class="
          flex flex-col
          sm:flex-row
          items-center
          p-5
          border-b border-gray-200
          dark:border-dark-5
        "
      >
        <h2 class="font-medium text-base mr-auto">
          {{ $t("views.wallet.balance") }}
        </h2>
      </div>

      <div class="p-5 md:flex items-baseline">
        <div
          class="relative text-2xl font-medium leading-5 mt-1 mr-3"
        >
          {{ this.displayBalance }}
        </div>
        <div class="text-sm mt-2">
          <ClockIcon size="1x" class="mr-1" /> {{ $t('views.wallet.updatedBalanceTimer', { time: lastUpdated }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Constants from "@/utils/constants";
import Coin from "@/utils/coin";
import QrCode from "./QrCode.vue";

export default defineComponent({
  name: "Address",
  components: {
    QrCode,
  },
  data: function () {
    return {
      address: "",
      blockExplorer: "",
      baseBalance: 0,
      updateBalanceInterval: null,
      lastUpdated: 1,
    };
  },
  mounted: async function () {
    this.address = this.$store.getters["common/wallet/address"];
    this.blockExplorer = `${Constants.BLOCK_EXPLORER}/account/${this.address}`;
    this.updateBalance();
    this.updateBalanceInterval = setInterval(() => {
      this.lastUpdated += 1;
      if (this.lastUpdated > 10) {
        this.updateBalance();
        this.lastUpdated = 1;
      }
    }, 1000);
  },
  unmounted: function () {
    clearInterval(this.updateBalanceInterval);
  },
  computed: {
    displayBalance: function () {
      try {
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        const displayCoin = coin.toDisplay(this.baseBalance);
        return `${displayCoin.amount} ${displayCoin.denom.toUpperCase()}`;
      } catch {
        return `0 ${Constants.DEFAULT_DISPLAY_CURRENCY.toUpperCase()}`;
      }
    },
  },
  methods: {
    updateBalance: function () {
      this.$store
        .dispatch("cosmos.bank.v1beta1/QueryBalance", {
          params: { address: this.address, denom: Constants.DEFAULT_CURRENCY },
          options: {},
        })
        .then((res) => {
          this.baseBalance = res.balance.amount
        });
    },
    copyToClipboard: async function () {
      await navigator.clipboard.writeText(this.address);
      this.$toast.success(this.$i18n.t('common.copied'))
    },
  },
});
</script>