<template>
  <div
    class="grid grid-cols-12 gap-6 px-5 py-3 items-center"
    :class="{ 'bg-gray-200 dark:bg-dark-1': index % 2 == 0 }"
  >
    <div class="col-span-12 xl:col-span-2 flex items-center">
      <div class="mr-3 bg-gray-600 p-2">
        <component :is="getTxIcon"></component>
      </div>
      <div class="">
        <div>{{ getTxText(tx) }}</div>
        <div class="text-gray-600">{{ getFmtTime(tx.response.timestamp) }}</div>
      </div>
    </div>

    <div class="col-span-12 xl:col-span-3 break-all">
      <a
          class="hover:underline text-nowrap"
          :href="`${blockExplorer}/account/${getTxDetails(tx)}`"
          target="_blank"
          >{{ getTxDetails(tx)}}</a>
    </div>

    <div
      class="col-span-12 xl:col-span-1 xl:text-right"
      v-if="tx.body.messages[0]['@type'] == '/cosmos.bank.v1beta1.MsgSend'"
    >
      <div v-for="(token, index) in getAmounts(tx)" v-bind:key="'am' + index">
        <div>
          {{
            tx.body.messages[0].from_address == address
              ? "-" + token.amount + " " + token.denom.toUpperCase()
              : "+" + token.amount + " " + token.denom.toUpperCase()
          }}
        </div>
      </div>
    </div>
    <div class="col-span-12 xl:col-span-1 xl:text-right" v-else>
      {{ getOtherAmount(tx) }}
    </div>
    <div class="col-span-12 xl:col-span-1 xl:text-right uppercase">
      {{ getFee(tx) }}
    </div>

    <div class="col-span-12 xl:col-span-5">
      <div class="overflow-ellipsis overflow-hidden">
        <a
          class="hover:underline text-nowrap"
          :href="`${blockExplorer}/transactions/${tx.response.txhash}`"
          target="_blank"
          >{{ tx.response.txhash }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Constants from "@/utils/constants";
import Coin from "@/utils/coin";
import dayjs from "dayjs";
import { decode } from "js-base64";

export default defineComponent({
  name: "TransactionItem",
  data: function () {
    return {
      address: "",
      blockExplorer: Constants.BLOCK_EXPLORER,
    };
  },
  props: {
    index: Number,
    tx: Object,
  },
  mounted: function () {
    this.address = this.$store.getters["common/wallet/address"];
  },
  computed: {
    getTxIcon: function () {
      if (this.tx.response.code != 0) {
        return "AlertTriangleIcon";
      }
      let msg = this.tx.body.messages[0];
      switch (msg["@type"]) {
        case "/cosmos.bank.v1beta1.MsgSend":
          return msg.from_address == this.address
            ? "ArrowUpIcon"
            : "ArrowDownIcon";
        case "/metdeal.cryptounce.depository.MsgMint":
          return "PlusIcon";
        case "/metdeal.cryptounce.depository.MsgBurn":
          return "TrashIcon";
        case "/metdeal.cryptounce.depository.MsgCreateBar":
          return "ArrowRightIcon";
        case "/metdeal.cryptounce.depository.MsgDeleteBar":
          return "ArrowLeftIcon";
        case "/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward":
          return "ArrowDownIcon";
        case "/cosmos.staking.v1beta1.MsgDelegate":
          return "CornerUpRightIcon";
        default:
          return "MailIcon";
      }
    },
  },
  methods: {
    getAmounts: function (tx) {
      const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
      const amounts = (tx.body.messages[0]?.amount ?? []).map((a) => {
        return coin.toDisplay(a.amount);
      });
      return amounts;
    },
    getFee: function(tx) {
      const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
      const fee = tx.auth_info?.fee?.amount[0].amount
      if (tx.body.messages[0]["@type"] == "/cosmos.bank.v1beta1.MsgSend") {
        // console.log(tx)
      }
      const feeDisp = coin.toDisplay(fee);
      return `${feeDisp.amount} ${feeDisp.denom}`
    },
    getFmtTime: function (time) {
      const momentTime = dayjs(time);
      return momentTime.format("D MMM, YYYY");
    },
    getDecoded: function (packet) {
      try {
        return JSON.parse(decode(packet));
      } catch (e) {
        return {};
      }
    },
    getOtherAmount: function (tx) {
      let coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
      switch (tx.body.messages[0]["@type"]) {
        case "/metdeal.cryptounce.depository.v1.MsgMint":
          coin = coin.toDisplay(tx.body.messages[0]["amount"]["amount"]);
          return `+${coin.amount} ${coin.denom.toUpperCase()}`;
        case "/metdeal.cryptounce.depository.v1.MsgBurn":
          coin = coin.toDisplay(tx.body.messages[0]["amount"]["amount"]);
          return `-${coin.amount} ${coin.denom.toUpperCase()}`;
        case "/metdeal.cryptounce.depository.v1.MsgCreateBar":
          return "+1 PC";
        case "/metdeal.cryptounce.depository.v1.MsgDeleteBar":
          return "-1 PC";
        case "/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward":
          return "";
        case "/cosmos.staking.v1beta1.MsgDelegate":
          coin = coin.toDisplay(tx.body.messages[0]["amount"]["amount"]);
          return `-${coin.amount} ${coin.denom.toUpperCase()}`;
        default:
          return "";
      }
    },
    getTxText: function (tx) {
      let text = "";
      if (tx.response.code != 0) {
        text = this.$i18n.t('views.wallet.transactionsHistory.failed')
      }
      if (tx?.body.messages.length > 1) {
        text = text + "Multiple messages";
      } else {
        if (tx.body.messages[0]["@type"] == "/cosmos.bank.v1beta1.MsgSend") {
          if (tx.body.messages[0].from_address == this.address) {
            text = text + this.$i18n.t('views.wallet.transactionsHistory.sent');
          } else if (tx.body.messages[0].to_address == this.address) {
            text = text + this.$i18n.t('views.wallet.transactionsHistory.recieved')
          }
        } else {
          switch (tx.body.messages[0]["@type"]) {
            case "/metdeal.cryptounce.depository.v1.MsgMint":
              text = text + this.$i18n.t('views.wallet.transactionsHistory.minted')
              break;
            case "/metdeal.cryptounce.depository.v1.MsgBurn":
              text = text + this.$i18n.t('views.wallet.transactionsHistory.burnt')
              break;
            case "/metdeal.cryptounce.depository.v1.MsgCreateBar":
              text = text + this.$i18n.t('views.wallet.transactionsHistory.barsDeposited')
              break;
            case "/metdeal.cryptounce.depository.v1.MsgDeleteBar":
              text = text + this.$i18n.t('views.wallet.transactionsHistory.barsWithdrawn')
              break;

            case "/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward":
              text = text + this.$i18n.t('views.wallet.transactionsHistory.rewardWithdrawn')
              break;

            case "/cosmos.staking.v1beta1.MsgDelegate":
              text = text + this.$i18n.t('views.wallet.transactionsHistory.amountDelegated')
              break;

            default:
              text = text + "Message";
              break;
          }
        }
      }
      return text;
    },
    getTxDetails: function (tx) {
      let text = "";
      if (tx.body.messages.length > 1) {
        text = text + "-";
      } else {
        if (tx.body.messages[0]["@type"] == "/cosmos.bank.v1beta1.MsgSend") {
          if (tx.body.messages[0].from_address == this.address) {
            text = text + tx.body.messages[0].to_address;
          }
          if (tx.body.messages[0].to_address == this.address) {
            text = text + tx.body.messages[0].from_address;
          }
        } else {
          switch (tx.body.messages[0]["@type"]) {
            case "/metdeal.cryptounce.depository.v1.MsgMint":
              text = text + tx.body.messages[0].minter;
              break;
            case "/metdeal.cryptounce.depository.v1.MsgBurn":
              text = text + tx.body.messages[0].burner;
              break;
            case "/metdeal.cryptounce.depository.v1.MsgCreateBar":
              text = text + tx.body.messages[0].creator;
              break;
            case "/metdeal.cryptounce.depository.v1.MsgDeleteBar":
              text = text + tx.body.messages[0].creator;
              break;

            case "/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward":
              text = text + tx.body.messages[0].validator_address;
              break;

            case "/cosmos.staking.v1beta1.MsgDelegate":
              text = text + tx.body.messages[0].validator_address;
              break;

            default:
              text = text + "Message";
              break;
          }
        }
      }
      return text;
    },
  },
});
</script>

<style>
</style>





