<template>
  <div class="col-span-12">
    <div class="intro-y box">
      <div
        class="
          flex flex-col
          sm:flex-row
          items-center
          p-5
          border-b border-gray-200
          dark:border-dark-5
        "
      >
        <h2 class="font-medium text-base mr-auto">
          {{ $t("views.wallet.sendCoins") }}
        </h2>
      </div>

      <div class="p-5 relative">
        <LoadingBlock v-if="processing" />

        <div class="relative">
          <label for="form-input-1" class="form-label">
            {{ $t("views.wallet.recipient") }}</label>
          <input
            id="form-input-1"
            type="text"
            class="form-control"
            @update:modelValue="updateFees"
            v-model="transfer.recipient"
            :placeholder="$t('views.wallet.recipientPlaceholder')"
            :class="{ 'border-theme-6': validations.visible && validations.recipient }"
          />
          <div class="text-theme-6 mt-2" v-if="validations.visible && validations.recipient">{{ validations.recipient }}</div>
          <div
            class="absolute right-5 top-9 cursor-pointer"
            @click="toggleMemo"
          >
            {{ (memoVisible ? '-' : '+') }} {{ $t("views.wallet.addMemoButton") }}
          </div>
        </div>

        <div class="mt-3 relative" v-if="memoVisible">
          <label for="form-input-1" class="form-label">
            {{ $t("views.wallet.memoLabel") }}
          </label>
          <input
            id="form-input-1"
            type="text"
            class="form-control"
            v-model="transfer.memo"
            @update:modelValue="updateFees"
            :placeholder="$t('views.wallet.memoPlaceholder')"
          />
        </div>

        <div class="mt-3 relative">
          <label for="form-input-1" class="form-label">{{
            $t("views.wallet.amountLabel")
          }}</label>
          <input
            id="form-input-1"
            type="text"
            v-model="transfer.amount"
            @update:modelValue="updateFees"
            v-on:keypress="$h.onlyFloat"
            class="form-control form-control-lg text-lg"
            placeholder="0"
            :class="{ 'border-theme-6': validations.visible && validations.amount }"
          />
          <div class="absolute right-5 top-9 text-lg uppercase">
            {{ this.defaultDenom }}
          </div>
          <div class="text-theme-6 mt-2" v-if="validations.visible && validations.amount">{{ validations.amount }}</div>
        </div>

        <div class="mt-5 flex items-center">
          <div class="">
            <div class="flex">
              <div class="mr-1">{{ $t('common.fee') }}:</div>
              <div class="font-semibold uppercase">
                {{ totalFee }} {{ defaultDenom }}
              </div>
              <div class="cursor-pointer ml-1" @click="toggleFeesDetails">
                <ArrowDownIcon v-if="!feesDetailsVisible" size="1x" />
                <ArrowUpIcon v-else size="1x" />
              </div>
            </div>
            <div v-if="feesDetailsVisible">
              <div class="flex">
                <div class="mr-1">{{ $t('views.wallet.gasFee') }}</div>
                <div class="font-semibold uppercase">
                  {{ transfer.networkFee }} {{ defaultDenom }}
                </div>
              </div>
              <div class="flex">
                <div class="mr-1">{{ $t('views.wallet.transferFee') }}</div>
                <div class="font-semibold uppercase">
                  {{ transfer.transferFee }} {{ defaultDenom }}
                </div>
              </div>
            </div>
          </div>
          <div class="ml-auto flex">
            <button class="btn btn-secondary w-24 mr-1 mb-2" v-on:click="reset">
              {{ $t('views.wallet.reset') }}
            </button>
            <button
              class="btn btn-primary mr-1 mb-2"
              v-on:click="sendTransaction"
            >
              {{ $t('views.wallet.sendTransaction') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.upcase {
  text-transform: uppercase;
}
</style>

<script>
import { defineComponent } from "vue";
import Coin from "@/utils/coin";
import Constants from "@/utils/constants";
import BigNumber from "bignumber.js";
import LoadingBlock from '@/components/common/LoadingBlock.vue'

const _ = require("lodash")

export default defineComponent({
  name: "SendAssets",
  components: {
    LoadingBlock
  },
  data: function () {
    return {
      transfer: {
        recipient: "",
        amount: "",
        memo: "",
        gas: 0,
        networkFee: 0,
        transferFee: 0,
      },
      validations: {
        recipient: "",
        amount: "",
        visible: false
      },
      defaultDenom: Constants.DEFAULT_DISPLAY_CURRENCY,
      memoVisible: false,
      feesDetailsVisible: false,
      address: "",
      processing: false,
    };
  },
  mounted: function () {
    this.address = this.$store.getters["common/wallet/address"];
  },
  computed: {
    totalFee: function () {
      let fee = BigNumber(this.transfer.networkFee)
        .plus(BigNumber(this.transfer.transferFee))
        .toFixed(6);
      return isNaN(fee) ? "0" : fee.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, "$1");
    },
  },
  methods: {
    toggleFeesDetails() {
      this.feesDetailsVisible = !this.feesDetailsVisible;
    },
    toggleMemo() {
      this.memoVisible = !this.memoVisible;
      if (!this.memoVisible) {
        this.transfer.memo = "";
      }
    },
    validate: function (visible = false) {
      this.validations.recipient = this.$h.validAddress(this.transfer.recipient) ? '' : this.$i18n.t('validations.invalidAddress')
      this.validations.amount = this.$h.validAmount(this.transfer.amount) ? '' : this.$i18n.t('validations.invalidAmount')
      this.validations.visible = visible
      if (!this.validations.amount) {
        let balance = this.$store.getters['cosmos.bank.v1beta1/getBalance']({ params: {address: this.address, denom: Constants.DEFAULT_CURRENCY} })
        balance = BigNumber(balance.balance?.amount ?? 0)
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY)
        if (BigNumber(coin.toBase(this.transfer.amount).amount).isGreaterThan(balance)) {
          this.validations.amount = this.$i18n.t('validations.insufficientFunds')
        }
      }
      return !(this.validations.recipient || this.validations.amount)
    },
    updateFees: _.debounce(async function ($value) {
      if (this.validate(false)) {
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        const value = {
          amount: [coin.toBase(this.transfer.amount)],
          toAddress: this.transfer.recipient,
          fromAddress: this.address,
        };
        try {
          const simulateResult = await this.$store.dispatch(
            "cosmos.bank.v1beta1/sendMsgSimulate",
            {
              value,
              fee: [{ amount: "0", denom: Constants.DEFAULT_CURRENCY }],
              memo: this.transfer.memo,
            }
          );
          this.transfer.gas = simulateResult.calculatedGas;

          let baseAmount = BigNumber(this.transfer.gas)
            .multipliedBy(BigNumber(Constants.GAS_PRICE))
            .multipliedBy(BigNumber(10).exponentiatedBy(coin.denomExponent()));
          baseAmount = baseAmount.integerValue(BigNumber.ROUND_CEIL);
          this.transfer.networkFee = coin.toDisplay(baseAmount).amount;
          let transferFee = simulateResult.log[0].events.find(
            (ev) => ev.type == "transfer-fee"
          );
          transferFee = transferFee.attributes.find(
            (a) => a.key == "amount"
          ).value;
          const [transferFeeAmount, transferFeeDenom] =
            transferFee.match(/\D+|\d+/g);
          this.transfer.transferFee = coin.toDisplay(transferFeeAmount).amount;
        } catch (e) {
          console.error(e);
        }
      }
    }, 500),
    sendTransaction: async function () {
      if (this.validate(true)) {
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        const value = {
          amount: [coin.toBase(this.transfer.amount)],
          toAddress: this.transfer.recipient,
          fromAddress: this.address,
        };
        const fee = coin.toBase(this.transfer.networkFee);
        this.processing = true;
        try {
          const txResult = await this.$store.dispatch(
            "cosmos.bank.v1beta1/sendMsgSend",
            {
              value,
              fee: [fee],
              memo: this.transfer.memo,
              gas: this.transfer.gas.toString(),
            }
          );
          if (txResult && !txResult.code) {
            this.$toast.success(this.$i18n.t('views.wallet.notificationSuccessTransfer'))
            await this.$store.dispatch("cosmos.bank.v1beta1/QueryAllBalances", {
              params: { address: this.address },
              options: {},
            });
            this.reset();
          }
        } catch (e) {
          this.$toast.error(this.$i18n.t('common.errorNotification'))
          console.error(e);
        } finally {
          this.processing = false;
        }
      }
    },
    reset: function () {
      this.transfer.recipient = ""
      this.transfer.amount = ""
      this.transfer.memo = ""
      this.transfer.gas = 0
      this.transfer.networkFee = 0
      this.transfer.transferFee = 0
      this.validations.recipient = ""
      this.validations.amount = ""
      this.validations.visible = false
    },
  },
});
</script>
