<template>
  <div id="qr-code-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog !w-80">
      <div class="modal-content">
        <a data-dismiss="modal" class="!-right-12 !-top-12" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <VueQrcode width="300" :value="address" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@/components/common/vue-qrcode/Main.vue";
export default {
  components: {
    VueQrcode,
  },
  props: {
    address: String,
  },
};
</script>

<style>
</style>