<template>
  <div class="col-span-12">
    <div class="intro-y box col-span-12">
      <div
        class="
          flex flex-col
          sm:flex-row
          items-center
          p-5
          border-b border-gray-200
          dark:border-dark-5
        "
      >
        <h2 class="font-medium text-base mr-auto">{{ $t('views.wallet.transactions') }}</h2>
      </div>

      <div class="grid grid-cols-12 gap-6 my-5 px-5 uppercase">
        <div class="hidden xl:block col-span-12 xl:col-span-2">{{ $t('views.wallet.status') }}</div>
        <div class="hidden xl:block col-span-12 xl:col-span-3">
          {{ $t('views.wallet.addressDetails') }}
        </div>
        <div class="hidden xl:block col-span-12 xl:col-span-1 text-right">{{ $t('views.wallet.amountLabel') }}</div>
        <div class="hidden xl:block col-span-12 xl:col-span-1 text-right">{{ $t('common.fee') }}</div>
        <div class="hidden xl:block col-span-12 xl:col-span-5">{{ $t('views.wallet.hash') }}</div>
      </div>

      <TransactionItem
        v-for="(transaction, index) in transactions"
        v-bind:key="index"
        :index="index"
        :tx="transaction"
      />

      <div class="text-center gap-6 my-5 px-5">
        <a class="btn btn-secondary w-38 mr-1 mb-2" target="_blank" :href="`${blockExplorer}/account/${address}`">
              {{ $t('views.wallet.showAllTransactions') }}
        </a>
      </div>


    </div>
  </div>
</template>

<script>
import Constants from "@/utils/constants";
import { defineComponent } from "vue";
import TransactionItem from "./TransactionItem.vue";

export default defineComponent({
  name: "TransactionsHistory",
  data() {
    return {
      address: "",
      blockExplorer: Constants.BLOCK_EXPLORER
    };
  },
  components: {
    TransactionItem,
  },
  computed: {
    sentTransactions: function () {
      return this.$store.getters["common/transfers/getGetTxsEvent"]({
        event: "transfer.sender%3D%27" + this.address + "%27",
      });
    },
    receivedTransactions: function () {
      return this.$store.getters["common/transfers/getGetTxsEvent"]({
        event: "transfer.recipient%3D%27" + this.address + "%27",
      });
    },
    transactions: function () {
      const allowedTypes = [
        '/cosmos.bank.v1beta1.MsgSend',
        '/cosmos.bank.v1beta1.MsgMultiSend',
        '/cosmos.staking.v1beta1.MsgDelegate',
        '/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward',
        '/metdeal.cryptounce.depository.v1.MsgMint',
        '/metdeal.cryptounce.depository.v1.MsgBurn'
      ]
      const sent =
        this.sentTransactions.txs?.map((tx, index) => {
          tx.response = this.sentTransactions.tx_responses[index];
          return tx;
        }) ?? [];
      const received =
        this.receivedTransactions.txs?.map((tx, index) => {
          tx.response = this.receivedTransactions.tx_responses[index];
          return tx;
        }) ?? [];
      const transactions = [...sent, ...received].sort(
        (a, b) => b.response.height - a.response.height
      ).filter((tx) => {
        return tx.body.messages.length > 0 && allowedTypes.includes(tx.body.messages[0]['@type'])
      })
      return Array.from(new Set(transactions.map(tx => tx.response.txhash))).map(hash => transactions.find(t => t.response.txhash == hash)).slice(0, 5);
    },
  },
  mounted: async function () {
    this.address = this.$store.getters["common/wallet/address"];

    await this.$store.dispatch("common/transfers/ServiceGetTxsEvent", {
      subscribe: true,
      event: "transfer.sender%3D%27" + this.address + "%27",
    });
    await this.$store.dispatch("common/transfers/ServiceGetTxsEvent", {
      subscribe: true,
      event: "transfer.recipient%3D%27" + this.address + "%27",
    });
  },
});
</script>

